<template>
  <div class="page-header-index-wide">
    <a-card :bordered="false" :bodyStyle="{ padding: '16px 0', height: '100%' }" :style="{ height: '100%' }"
            title="菜单管理">
      <div class="account-settings-info-main">
        <div class="account-settings-info-right">
          <div class="panel">
            <div ref="treeContent">
              <div class="tree-panel">
                <z-tree ref="treeRef"
                        :load-url="treeLoadUrl"
                        @deleteClick="treeDeleteClickHandle"
                        @addClick="treeAddClickHandle"
                        @editClick="treeAddClickHandle"
                        @treeNodeClick="treeNodeClickHandle"
                        @dropNode="handleDrop"></z-tree>
              </div>
            </div>
            <div class=content-panel>
              <div class="detail-content-panel">
                <div class="panel-body">
                  <div class="panel-body-title">
                    <span>资源详情</span>
                  </div>
                  <resource-form ref="editRef"
                                 @editSuccess="handleEditSuccess"></resource-form>
                </div>
              </div>
            </div>
            <a-modal
              title="资源添加"
              v-model="visible"
              okText="添加"
              :maskClosable="false"
              cancelText="取消"
              :confirmLoading="addStatus"
              @ok="handleOk">
              <resource-form ref="addRef"
                             type="add"
                             @addSuccess="handleAddSuccess"
                             @addError="handleAddError"
                             @addStart="handleAddStart"
              ></resource-form>
            </a-modal>
          </div>
        </div>
      </div>
    </a-card>
  </div>
</template>
<script>

  import SERVICE_URLS from '../../../api/service.url'
  import ZTree from '../../../components/ztree/index'
  import ResourceForm from './ResourceForm'
  import menuTile from '../../common/mixins/menuTitle'

  export default {
    name: 'ResourceSystemSetting',
    mixins: [menuTile],
    data() {
      return {
        treeLoadUrl: SERVICE_URLS.resource.tree,
        visible: false,
        addStatus: false
      }
    },
    methods: {
      handleDrop(dropNode, targetNode, moveType) {
        if (dropNode && targetNode && moveType) {
          this.$http(this, {
            url: SERVICE_URLS.resource.moveNode,
            data: {
              dropNodeId: dropNode.id,
              targetNodeId: targetNode.id,
              moveType
            },
            successTipsContent: '拖拽更新成功',
            success: () => {
              this.$refs.treeRef.loadTree()
            }
          })
        }
      },
      treeNodeClickHandle(treeNode) {
        this.$refs['editRef'].loadData(treeNode.id)
      },
      treeDeleteClickHandle(treeNode) {
        this.showDeleteConfirm(treeNode)
      },
      treeAddClickHandle(treeNode) {
        this.visible = true
        this.$nextTick(() => {
          this.$refs['addRef'].loadSelect(treeNode.id)
        })
      },
      handleOk() {
        this.$refs['addRef'].submitAdd()
      },
      handleAddStart() {
        this.addStatus = true
      },
      handleEditSuccess(value) {
        this.$refs['treeRef'].updateSelectNode(value)
      },
      handleAddSuccess(value) {
        this.$refs['treeRef'].addNode(value)
        this.addStatus = false
        this.visible = false
      },
      handleAddError() {
        this.addStatus = false
      },
      showDeleteConfirm(treeNode) {
        this.$confirm({
          title: '确定删除当前资源？',
          content: '会自动删除所有子节点，请谨慎操作！',
          okText: '删除',
          okType: 'danger',
          cancelText: '取消',
          onOk: () => {
            return new Promise((resolve, reject) => {
              this.deleteDelete(treeNode, resolve)
            }).catch(() => this.$error('Oops errors!'))
          }
        })
      },
      deleteDelete(treeNode, resolve) {
        this.$http(this, {
          url: SERVICE_URLS.resource.delete,
          params: {
            id: treeNode.id
          },
          success: () => {
            this.$refs['treeRef'].deleteNode(treeNode)
            resolve()
          }
        })
      }
    },
    components: { ZTree, ResourceForm }
  }
</script>