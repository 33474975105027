<template>
  <a-spin :spinning="spinShow">
    <a-form layout="vertical" :form="form">
      <a-form-item
        label="资源名称">
        <a-input
          style="width: 412px"
          v-decorator="['name',
                {rules:[
                  {type:'string',required:true,message:'资源名称不能为空'},
                  {type:'string',max:30,message:'资源名称不能超过30个字符'},
                  ],trigger:'change'}
                ]"
          placeholder="输入资源名称"></a-input>
      </a-form-item>
      <div style="width: 412px">
        <a-row>
          <a-col span="12">
            <a-form-item label="设置父节点">
              <a-select
                allowClear
                showSearch
                optionFilterProp="children"
                placeholder="请选择上级节点"
                v-decorator="['parentId']"
                style="width: 200px">
                <a-select-option :key="item.key"
                                 :value="item.key"
                                 :title="item.value"
                                 v-for="item in options">
                  {{item.value}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="12">
            <a-form-item class="m-l-6" label="设置资源类型" required>
              <a-select placeholder="请选择资源类型"
                        v-decorator="['resourceType', {
                            rules:[{required:true,message:'请选择资源类型'}]
                            ,initialValue:'column'}]"
                        @change="resourceTypeChangeHandle"
                        style="width: 200px">
                <a-select-option :key="item.key"
                                 :value="item.key"
                                 :title="item.value"
                                 v-for='item in [{"key":"column","value":"栏目"},{"key":"button","value":"按钮"}]'>
                  {{item.value}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </div>
      <a-form-item v-show="formItem.resourceType === 'column'" label="设置资源对应路由">
        <a-input
          style="width: 412px"
          v-decorator="['urls', {
              rules:[
              {type:'string',required:true,message:'资源对应路由不能为空'},
              {type:'string',max:255,message:'资源对应路由不能超过255个字符'},
              ]
              ,trigger:'change'}]"
          placeholder="输入资源对应路由（完整路径）"></a-input>
      </a-form-item>
      <a-form-item label="设置权限标示" v-show="formItem.resourceType === 'button'">
        <a-input
          style="width: 412px"
          v-decorator="['identity', {
              rules:[
              {type:'string',required:true,message:'权限标示不能为空'},
              {type:'string',max:255,message:'权限标示不能超过255个字符'},
              ],trigger:'change'}]"
          placeholder="输入设置权限表示"></a-input>
      </a-form-item>
      <a-form-item label="设置排序号（资源显示顺序，asc排列）">
        <a-input-number
          style="width: 110px"
          :step="0.1"
          v-decorator="['sorter', {rules:[
          {type:'number',required:true,message:'排序号必须为数字'}
          ],trigger:'change',initialValue:0}]"
          placeholder="输入排序号"></a-input-number>
      </a-form-item>
      <a-form-item label="设置是否状态（禁用后将不在栏目中显示）">
        <a-switch checkedChildren="启用" unCheckedChildren="禁用"
                  v-decorator="['show', {
                      rules:[{type:'boolean',required:true}],
                      trigger:'change',valuePropName:'checked',initialValue:true}]"></a-switch>
      </a-form-item>
      <a-form-item label="图标（使用ant icon组件中已包含内容）" v-show="formItem.resourceType === 'column'">
        <a-input
          style="width: 412px"
          v-decorator="['iconCode', {rules:[{type:'string',max:30}],trigger:'change'}]"
          placeholder="输入图标type"></a-input>
      </a-form-item>
      <a-form-item label="别名">
        <a-input
          style="width: 412px"
          v-decorator="['alias', {rules:[{type:'string',max:30}],trigger:'change'}]"
          placeholder="输入别名"></a-input>
      </a-form-item>
      <a-form-item label="所有父节点ID信息" v-if="type === 'edit'">
        {{formItem.parentIds}}
      </a-form-item>
      <a-form-item label="最近更新时间" v-if="type === 'edit'">
        {{formItem.lastUpdated}}
      </a-form-item>
      <a-form-item label="资源创建时间" v-if="type === 'edit'">
        {{formItem.dateCreated}}
      </a-form-item>
      <a-form-item label="资源创建人（用户Username）" v-if="type === 'edit'">
        {{formItem.creater}}
      </a-form-item>
      <div style="width: 412px">
        <a-form-item v-if="type === 'edit'">
          <a-affix :offsetBottom="10">
            <a-button type="primary" style="float: right"
                      :loading="ifSub"
                      @click="submitEdit">保存修改
            </a-button>
          </a-affix>
        </a-form-item>
      </div>
    </a-form>
  </a-spin>
</template>

<script>

  import SERVICE_URLS from '../../../api/service.url'
  import resourceVO from '../../../api/vo/settings/resourcesVO'

  export default {
    name: 'ResourceForm',
    props: {
      type: {
        type: String,
        default: 'edit'
      }
    },
    data () {
      return {
        options: [],
        formItem: resourceVO(),
        spinShow: false,
        form: this.$form.createForm(this),
        ifSub: false
      }
    },
    methods: {
      /*
       * 通过ID获取数据
       */
      loadData (id) {
        const self = this
        this.$http(self, {
          url: SERVICE_URLS.resource.view,
          params: {
            id: id
          },
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.options = data.body.select
            //一定要注意 不然会导致双向绑定选择框无法选中值
            this.$nextTick(() => {
              this.setFields(data.body.content)
            })
          }
        })
      },

      setFields (values) {
        this.formItem = resourceVO()
        Object.assign(this.formItem, values)
        this.formItem.parentId = values.parentId
        this.form.resetFields()
        this.form.setFieldsValue({
          name: this.formItem.name,
          parentId: this.formItem.parentId,
          resourceType: this.formItem.resourceType,
          urls: this.formItem.urls,
          sorter: this.formItem.sorter,
          iconCode: this.formItem.iconCode,
          show: this.formItem.show,
          identity: this.formItem.identity,
          alias: this.formItem.alias
        })
      },
      setVOFields (values) {
        this.formItem.name = values.name
        this.formItem.parentId = values.parentId
        this.formItem.resourceType = values.resourceType
        this.formItem.sorter = values.sorter
        this.formItem.show = values.show
        if (this.formItem.resourceType === 'button') {
          this.formItem.identity = values.identity
        } else {
          this.formItem.urls = values.urls
          this.formItem.iconCode = values.iconCode
        }
        this.formItem.alias = values.alias
      },
      /**
       * 加载下拉框数据，添加时使用
       * @param id 默认选中值
       */
      loadSelect (id) {
        this.$http(this, {
          url: SERVICE_URLS.resource.select,
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.options = data.body
            this.$nextTick(() => {
              this.form.setFieldsValue({
                parentId: id
              })
            })
          }
        })
      },
      submitEdit () {
        const {
          form: { validateFields }
        } = this
        const validateFieldsKey = this.formItem.resourceType === 'button' ? ['name', 'parentId', 'resourceType', 'sorter', 'show', 'identity', 'alias']
          : ['name', 'parentId', 'resourceType', 'sorter', 'show', 'urls', 'iconCode', 'alias']
        validateFields(validateFieldsKey, { force: true }, (err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.resource.update,
              data: this.formItem,
              params: {
                id: this.formItem.id
              },
              success: (data) => {
                this.setFields(data.body)
                this.$emit('editSuccess', data.body)
              }
            })
          }
        })
      },
      submitAdd () {
        const {
          form: { validateFields, resetFields }
        } = this
        const validateFieldsKey = this.formItem.resourceType === 'button' ? ['name', 'parentId', 'resourceType', 'sorter', 'show', 'identity', 'alias']
          : ['name', 'parentId', 'resourceType', 'sorter', 'show', 'urls', 'iconCode', 'alias']
        validateFields(validateFieldsKey, { force: true }, (err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$emit('addStart')
            this.$http(this, {
              url: SERVICE_URLS.resource.create,
              data: this.formItem,
              success: (data) => {
                //添加成功，清空表单数据，并触发回调
                this.$emit('addSuccess', data.body)
                //清空表单数据
                this.formItem = resourceVO()
                resetFields()
                this.form.resetFields()
              },
              error: () => {
                this.$emit('addError')
              }
            })
          }
        })
      },
      resourceTypeChangeHandle (value) {
        this.formItem.resourceType = value
      }
    }
  }
</script>