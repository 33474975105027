// 资源VO对象
const resourceVo = function () {
  return {
    id: '',
    name: '',
    identity: '',
    urls: '',
    parentId: null,
    parentIds: '',
    sorter: 0,
    resourceType: 'column',
    iconCode: '',
    show: true,
    beforeShow: false,
    defaultChoose: false,
    addCachePage: false,
    creater: '',
    dateCreated: '',
    lastUpdated: '',
    alias: ''
  }
}

export default resourceVo
